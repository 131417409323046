body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #070606;
  padding-top: 90px;
}
@font-face {
  font-family: 'Sixtyfour';
  src: url('./Fonts/Sixtyfour-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.nav, .site-title, .green-text {
  font-family: 'Sixtyfour', cursive; /* Fallback to a generic cursive font if needed */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.color-picker-container {
  padding: 3px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background-color: #fff; /* Or any color that fits your app's theme */
  margin-top: 20px;  /* Adjust this value according to your preference */
}

.pentagon-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: auto;
  box-sizing: border-box;
  background-color: #070606;
  border-radius: 10px;
}

.pentagon-chart {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.pentagon-chart-canvas {
  max-width: 500px;
  max-height: 500px;
}

.green-text {
  color: #ffffff;
}

.pentagon-chart-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  gap: 10px;
}

.pentagon-chart-inputs input[type="text"],
.pentagon-chart-inputs input[type="number"] {
  padding: 10px;
  margin-bottom: 5px;
  width: 100%; /* Adjusted width */
  box-sizing: border-box;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  outline: none;
}

.title-and-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.title-input-container input[type="text"] {
  width: 80%;
  flex-grow: 1;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  outline: none;
}

.update-button-container {
  display: flex;
  flex-direction: row; /* Aligns items horizontally */
  justify-content: flex-end; /* Aligns items to the right of the container */
  align-items: center; /* Aligns items vertically in the center */
  gap: 10px; /* Provides space between the buttons */
}


.update-button-container button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #555;
  color: #fff;
  transition: background-color 0.3s;
}

.update-button-container button:hover {
  background-color: #777;
}

@media screen and (min-width: 992px) {
  body {
    display: flex;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Use min-height instead of height */
    
  }
  .pentagon-chart-container {
    max-width: 80%;
    margin: 0 auto;
  }

  .site-title {
    font-size: 0.2rem;
  }

  .pentagon-chart,
  .pentagon-chart-inputs,
  .update-button-container,
  .title-and-button-container {
    width: auto;
    margin: 0 auto;
    overflow-y: auto;
  }
  .pentagon-chart-canvas {
    width: auto;
    height: auto;
    max-width: 1600px;
    max-height: 1600px;
    margin: 0 auto;
  }
  .pentagon-chart-inputs {
    max-width: 800px;
    margin: 0 auto;
  }
}


.nav {
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 0 4rem; /* Add horizontal padding on both sides */
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.site-title {
  font-size: 2rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
  font-size: 1rem;
}

.nav li.active {
  background-color: #555;
}

.nav li:hover {
  background-color: #777;
}

@media screen and (max-width: 991px) {
  .site-title {
    font-size: 1rem; /* Slightly smaller size for tablets */
  }
  .nav a {
    font-size: 0.6rem; /* Smaller size for other navbar text on mobile */
  }
}

/* Media query for mobile devices (devices with max-width up to 767px) */
@media screen and (max-width: 767px) {
  .site-title {
    font-size: 1rem; 
  }
 
}

/* Optionally, you can have another query for very small devices */
@media screen and (max-width: 480px) {
  .site-title {
    font-size: 0.7rem; /* Even smaller size for very small devices */
  }

}